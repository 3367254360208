enum Factors {
  BATHROOMS = 1,
  OPTIONS = 2,
  QUALITY = 3,
  PROPERTYSERVICES = 4,
  LOCATIONSPECS = 5,
  GENERALSPECS = 6,
  LOCATIONSPECSINVESTMENT = 7,
  PROPERTYSERVICESINVESTMENT = 8,
  ELECTRICITY = 9,
  PROPERTYSERVICESLITERAL = 10,
  PROPERTYSERVICESCOMMERCIAL = 11,
  PROPERTYSERVICESINDUSTRAIL = 12,
}

export default Factors;
